import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

const API_BASE_URL = "https://cp2jhdmmol.execute-api.us-west-2.amazonaws.com";
// const API_BASE_URL = "https://pqht0kqjol.execute-api.us-west-2.amazonaws.com"; // deployed

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Disclosures = () => (
  <div className="text-sm text-gray-700 space-y-2">
    <p>
      DISCLOSURES: By claiming and/or using your virtual meal credit, you agree
      to our terms of use. Cards can be used for food purchases anywhere that
      accepts Visa or Mastercard credit cards. Card funds will be available
      until the end of the "use by" date (Pacific Time Zone). The Card is not a
      gift card.
    </p>
    <p>
      Your Card remains the property of the Issuer and must be returned upon
      request. We may cancel, revoke, repossess or restrict the use of your Card
      at any time. We may decline to authorize or reverse Charges or suspend
      your Card for any reason including violation of these Terms or suspected
      fraud. We also may decline to authorize Charges at merchants characterized
      by the Card Network or us as prohibited merchants. We are not responsible
      for losses resulting from declined or reversed Charges. Merchants
      typically accept all Card Network-branded Cards; however, we are not
      responsible and will have no liability if a merchant refuses to honor a
      Card or accept a Transaction.
    </p>
    <p>
      You are responsible for securing your Cards, account numbers and Card
      security features (including the CVC/CVV and PIN).
    </p>
    {/* We use less vertical padding on card footers at all sizes than on headers or body sections */}
  </div>
);

export default function Card() {
  const queryParams = new URLSearchParams(window.location.search);

  // {
  //   number: cardNumber,
  //   exp_month: cardDetails.exp_month,
  //   exp_year: cardDetails.exp_year,
  //   cvc: cardDetails.cvc,
  // }

  const name = queryParams.get("name");
  const number = queryParams.get("number");
  const exp_month = queryParams.get("exp_month");
  const exp_year = queryParams.get("exp_year");
  const cvc = queryParams.get("cvc");

  // const cardDetails = {
  //   name,
  //   number,
  //   exp_month,
  //   exp_year: ,
  //   cvc ,
  // };

  // console.log(email);

  return (
    <div
      className="card"
      style={{
        boxSizing: "border-box",
        fontFamily: "Space Mono, monospace",
        width: "320px",
        height: "190px",
        WebkitPerspective: "600px",
        MozPerspective: "600px",
        perspective: "600px",
        margin: "40px auto",
      }}
    >
      <div
        className="card__front card__part"
        style={{
          boxSizing: "border-box",
          fontFamily: "Space Mono, monospace",
          boxShadow: "1px 1px #aaa3a3",
          top: 0,
          position: "absolute",
          zIndex: 1000,
          left: 0,
          display: "inline-block",
          width: "320px",
          height: "206px",
          backgroundImage: "linear-gradient(to right bottom, #e28dac, #6e82bd)",
          backgroundColor: "#9987b7",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          borderRadius: "12px",
          WebkitTransition: "all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
          MozTransition: "all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
          msTransition: "all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
          OTransition: "all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
          transition: "all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
          WebkitTransformStyle: "preserve-3d",
          MozTransformStyle: "preserve-3d",
          WebkitBackfaceVisibility: "hidden",
          MozBackfaceVisibility: "hidden",
          padding: "18px",
          WebkitTransform: "rotateY(0)",
          MozTransform: "rotateY(0)",
          textShadow: "1px 1px #46547e",
        }}
      >
        <table style={{ width: "100%", marginBottom: "10px" }}>
          <tbody>
            <tr>
              <td style={{ width: "50%", verticalAlign: "bottom" }}>
                <span
                  className="card_numer"
                  style={{
                    boxSizing: "border-box",
                    fontFamily: "Azeret Mono, Space Mono, monospace",
                    fontWeight: "bold",
                    width: "100%",
                    fontSize: "26px",
                    color: "#fff",
                  }}
                >
                  ${"10"}
                </span>
              </td>
              <td style={{ width: "50%", verticalAlign: "bottom" }}>
                <img
                  className="card__front-logo card__logo"
                  src="https://www.mckaulay.com/images/hackathon-logo-sm-white.png"
                  alt="WINFO"
                  style={{
                    boxSizing: "border-box",
                    fontFamily: "Space Mono, monospace",
                    height: "30px",
                    float: "right",
                    marginBottom: "10px",
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <p
          style={{
            boxSizing: "border-box",
            fontFamily: "Azeret Mono, Space Mono, monospace",
            display: "block",
            width: "100%",
            fontSize: "18px",
            color: "#fff",
            marginBottom: "5px",
            marginTop: "5px",
          }}
        >
          Use by: March 1, 2022
        </p>
        <p
          className="card_number"
          style={{
            boxSizing: "border-box",
            fontFamily: "Azeret Mono, Space Mono, monospace",
            display: "block",
            width: "100%",
            fontSize: "20px",
            letterSpacing: "2px",
            color: "#fff",
            textAlign: "center",
            marginBottom: "2px",
            marginTop: "12px",
          }}
        >
          {number || "**** **** **** ****"}
        </p>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ width: "50%" }}>
                <div
                  className="card__space-25"
                  style={{
                    boxSizing: "border-box",
                    fontFamily: "Azeret Mono, Space Mono, monospace",
                  }}
                >
                  <span
                    className="card__label"
                    style={{
                      boxSizing: "border-box",
                      fontFamily: "Space Mono, monospace",
                      fontSize: "10px",
                      textTransform: "uppercase",
                      color: "rgba(255,255,255,0.8)",
                      letterSpacing: "1px",
                    }}
                  >
                    Expires
                  </span>
                  <p
                    className="card__info"
                    style={{
                      boxSizing: "border-box",
                      fontFamily: "Azeret Mono, Space Mono, monospace",
                      marginBottom: 0,
                      marginTop: "5px",
                      fontSize: "16px",
                      lineHeight: "18px",
                      color: "#fff",
                      letterSpacing: "1px",
                      textTransform: "uppercase",
                    }}
                  >
                    {exp_month || "**"}/{exp_year || "**"}
                  </p>
                </div>
              </td>
              <td style={{ width: "25%" }}>
                <div
                  className="card__space-25"
                  style={{
                    boxSizing: "border-box",
                    fontFamily: "Space Mono, monospace",
                  }}
                >
                  <span
                    className="card__label"
                    style={{
                      boxSizing: "border-box",
                      fontFamily: "Azeret Mono, Space Mono, monospace",
                      fontSize: "10px",
                      textTransform: "uppercase",
                      color: "rgba(255,255,255,0.8)",
                      letterSpacing: "1px",
                    }}
                  >
                    CVC
                  </span>
                  <p
                    className="card__info"
                    style={{
                      boxSizing: "border-box",
                      fontFamily: "Azeret Mono, Space Mono, monospace",
                      marginBottom: 0,
                      marginTop: "5px",
                      fontSize: "16px",
                      lineHeight: "18px",
                      color: "#fff",
                      letterSpacing: "1px",
                      textTransform: "uppercase",
                    }}
                  >
                    {cvc || "***"}
                  </p>
                </div>
              </td>
              <td style={{ width: "25%", textAlign: "right" }}>
                <img
                  src="https://www.mckaulay.com/images/Visa_2021-white.png"
                  alt="Visa"
                  style={{
                    boxSizing: "border-box",
                    marginTop: "15px",
                    fontFamily: "Azeret Mono, Space Mono, monospace",
                    height: "25px",
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="card__info"
          style={{
            boxSizing: "border-box",
            fontFamily: "Azeret Mono, Space Mono, monospace",
            marginBottom: 0,
            marginTop: "-5px",
            fontSize: "14px",
            color: "#fff",
            letterSpacing: "1px",
            textTransform: "uppercase",
            marginLeft: "4px",
          }}
        >
          {name || ""}
        </p>
      </div>
    </div>
  );
}
