import React from "react";
import { CheckIcon, ClockIcon, XIcon } from "@heroicons/react/outline";
import DelayAlert from "./DelayAlert";

export default ({ visible, content, onDismiss }) => {
  return (
    <div
      className="fixed z-10 inset-0 overflow-y-auto"
      style={visible ? {} : { display: "none" }}
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        {/* <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    --> */}
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        {/* <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    --> */}
        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style={visible ? {} : { display: "none" }}
        >
          {visible?.error && visible?.message ? (
            <div>
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                <XIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {visible?.message}
                </h3>
                <div className="mt-2 space-y-2">
                  <p className="text-sm text-gray-500">
                    If you think this is incorrect, please contact us at{" "}
                    <a
                      href="mailto:info@giftcardtrack.com"
                      className="text-sm text-blue-500"
                    >
                      info@giftcardtrack.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          ) : visible?.error ? (
            <div>
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                <XIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  There was an error claiming meal credit
                </h3>
                <div className="mt-2 space-y-2">
                  <p className="text-sm text-gray-500">
                    Something went wrong when claiming your meal credit. We
                    saved your information and are investigating. We will
                    contact you shortly to resolve the issue.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                <CheckIcon
                  className="h-6 w-6 text-green-600"
                  aria-hidden="true"
                />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Successfully claimed meal credit
                </h3>
                <div className="mt-2 space-y-2">
                  <p className="text-sm text-gray-500">
                    Thank you for providing your personal information.
                  </p>
                  <p className="text-sm text-gray-500">
                    Your gift card will be sent to your provided email address
                    soon.
                  </p>
                  {/* <DelayAlert /> */}
                  {/* <p className="text-sm text-gray-500">
                    A message has been sent to your email containing your
                    virtual card. If you do not receive the message, please
                    contact us at{" "}
                    <a
                      href="mailto:info@giftcardtrack.com"
                      className="text-sm text-blue-500"
                    >
                      info@giftcardtrack.com
                    </a>
                  </p> */}
                  <p className="text-sm text-gray-500">
                    You may now close this website.
                  </p>
                  {/* <p className="text-sm text-gray-500">
                  Confirmation number: <strong>123456</strong>
                </p> */}
                </div>
              </div>
            </div>
          )}

          {/* <div>
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
              <ClockIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                Successfully claimed meal credit
              </h3>
              <div className="mt-2 space-y-2">
                <p className="text-sm text-gray-500">
                  Thank you for providing your personal information.
                </p>
                <p className="text-sm text-gray-500">
                  A message has been sent to your email containing your virtual
                  card. If you do not receive the message, please contact us at{" "}
                  <a
                    href="mailto:info@giftcardtrack.com"
                    className="text-sm text-blue-500"
                  >
                    info@giftcardtrack.com
                  </a>
                </p>
                <p className="text-sm text-gray-500">
                  You may now close this website.
                </p>
              </div>
            </div>
          </div> */}
          {/* <div className="mt-5 sm:mt-6">
            <button
              type="button"
              onClick={() => {
                window.close();
              }}  
              className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
            >
              Exit
            </button>

            <Link
              to="/"
              role="button"
              className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
            >
              Exit
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
};
