import { useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import axios from "axios";
import moment from "moment";

import PersonalDetails from "./PersonalDetails";

const API_BASE_URL = "https://cp2jhdmmol.execute-api.us-west-2.amazonaws.com";
// const API_BASE_URL = "https://pqht0kqjol.execute-api.us-west-2.amazonaws.com"; // deployed

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Disclosures = () => (
  <div className="text-sm text-gray-700 space-y-2">
    <p>
      DISCLOSURES: By claiming a Gift Card, you agree to our terms of use. Cards
      may be claimed until the end of the "claim by" date (Pacific Time Zone).
      Offer available until supplies run out. Additional merchant gift card
      policies may apply. We are not responsible for losses resulting from
      declined or reversed Charges. Merchants typically accept merchant-issued
      Gift Cards; however, we are not responsible and will have no liability if
      a merchant refuses to honor a Card or accept a Transaction.
    </p>
    <p>
      You are responsible for securing your Cards, account numbers and Card
      security features.
    </p>
    {/* We use less vertical padding on card footers at all sizes than on headers or body sections */}
  </div>
);

const Card = () => (
  <div className="bg-white overflow-hidden shadow rounded-lg">
    <div className="px-4 py-5 sm:p-6">
      <PersonalDetails />
    </div>
    <div className="bg-gray-50 px-4 py-4 sm:px-6">
      Footer content...
      {/* We use less vertical padding on card footers at all sizes than on headers or body sections */}
    </div>
  </div>
);

const Banner = () => (
  <div className="relative bg-red-600">
    <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
      <div className="pr-16 sm:text-center sm:px-16">
        <p className="font-medium text-white">
          Our banking partner is experiencing a delay in issuing virtual cards.
          <br />
          We will be working to email your card to you soon, and apologize for
          the inconvenience.
          {/* <span className="md:hidden">We announced a new product!</span>
          <span className="hidden md:inline">
            Big news! We're excited to announce a brand new product.
          </span> */}
        </p>
      </div>
      {/* <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
          <button
            type="button"
            className="flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
          >
            <span className="sr-only">Dismiss</span>
            <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </button>
        </div> */}
    </div>
  </div>
);

const offerIsValid = ({ offer }) => {
  if (
    moment().isAfter("2022-03-01", "day")
    // &&
    // ![
    //   "pk6zFj2Xqrv9JbD3XXg1PQpguOc7wkvD5fz1",
    //   "PPRRDFI6AeVwyImvZZ3lZrKTYIRZ-iRh4N1YD9nyy4xgePdFUHg-HIhxIAiliYz0", // Jeanne
    // ].includes(offer.claimId)
  ) {
    // console.log("offer is expired");
    return false;
  }

  // Disable check if offer has been redeemed to allow gift card selection
  // if (offer?.redeemed) {
  //   // console.log("offer has already been redeemed");
  //   return false;
  // }

  if (offer?.card_selection) {
    // console.log("offer has already been redeemed");
    return false;
  }

  return true;
};

export default function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const offerId = queryParams.get("offer");
  const email = queryParams.get("email");
  const [offer, setOffer] = useState(false);
  const [loadingOffer, setLoadingOffer] = useState(false);

  useEffect(async () => {
    setLoadingOffer(true);
    try {
      const offer = await axios({
        method: "get",
        url: API_BASE_URL + "/claim/" + offerId,
      });
      setOffer(offer.data);
    } catch (error) {}
    setLoadingOffer(false);
  }, []);

  // console.log(email);

  return (
    <div className="min-h-full">
      {/* <header className="bg-white shadow-sm">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
            <h1 className="text-lg leading-6 font-semibold text-gray-900">
              Dashboard
            </h1>
          </div>
        </header> */}
      {/* <Banner /> */}
      <main>
        {(loadingOffer || offer) && offerIsValid({ offer }) ? (
          <div className="max-w-2xl mx-auto py-6 sm:px-6 lg:px-8">
            <img
              src="https://winfo.ischool.uw.edu/img/hackathon-logo.png"
              alt="WINFO"
              class="w-full max-w-xs mx-auto my-10"
            />
            <div class="text-center mb-6 space-y-2 px-4">
              <h1 class="text-2xl font-bold">
                Claim your virtual Hackathon meal credit
              </h1>
              <p>
                Please fill out the following form to claim your $10 virtual
                meal credit.
              </p>
              <p>
                A virtual card will be sent to your email shortly after you
                claim your credit.
              </p>
            </div>
            {/* Replace with your content */}
            <PersonalDetails offer={offer} />

            {/* <div className="px-4 py-4 sm:px-0">
              <div className="border-4 border-dashed border-gray-200 rounded-lg h-96" />
            </div> */}
            {/* /End replace */}
            <div class="mt-10 space-y-6 px-4">
              <Disclosures />
              <p class="text-gray-700">Copyright &copy; GiftCardTrack.com</p>
            </div>
          </div>
        ) : (
          <div className="max-w-2xl mx-auto py-6 sm:px-6 lg:px-8">
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                {/* This offer has expired */}
                This offer has expired or has already been redeemed
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
}
