import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  ExclamationIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/solid";
import axios from "axios";
import validator from "validator";
import Modal from "./Modal";
import RadioCards from "./RadioCards";

import US_STATES from "./states";

const API_BASE_URL = "https://cp2jhdmmol.execute-api.us-west-2.amazonaws.com";
// const API_BASE_URL = "https://pqht0kqjol.execute-api.us-west-2.amazonaws.com"; // deployed

const Alert = () => (
  <div className="rounded-md bg-blue-50 p-4">
    <div className="flex">
      <div className="flex-shrink-0">
        <QuestionMarkCircleIcon
          className="h-5 w-5 text-blue-400"
          aria-hidden="true"
        />
      </div>
      <div className="ml-3">
        <h3 className="text-sm font-medium text-blue-800">
          Why do we need to collect this information?
        </h3>
        <div className="mt-2 text-sm text-blue-700">
          <p>
            We are required to collect your contact information to comply with
            federal banking regulations. Your personal details will only be used
            for the purpose of providing your virtual card.
          </p>
        </div>
      </div>
    </div>
  </div>
);

const Spinner = ({ color = "gray", className }) => (
  <svg
    // className="animate-spin h-5 w-5 text-gray-900"
    className={`animate-spin h-5 w-5 ${className}`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

const PersonalDetails = ({ offer }) => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [resultModal, setResultModal] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const offerId = queryParams.get("offer");
  const email = queryParams.get("email");

  // console.log(resultModal);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { state: "NONE_SELECTED" },
  });

  const onSubmit = async (data) => {
    console.log(data);
    // return;
    setFormSubmitting(true);

    const params = offer?.redeemed
      ? { card_selection: data.card_selection }
      : data;

    try {
      await axios({
        method: "post",
        url: API_BASE_URL + "/claim/" + offerId,
        data: params,
      });
      setResultModal(true);
    } catch (error) {
      // console.error(error);
      // console.log(error.response);
      setResultModal({ error: true, message: error?.response?.data });
    }
    setFormSubmitting(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
            {!offer?.redeemed && (
              <>
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Personal Information
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Use a permanent address where you can receive mail.
                  </p>
                </div>
                <Alert />
              </>
            )}

            <div className="grid grid-cols-6 gap-6">
              {!offer?.redeemed && (
                <>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="first_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      First name
                    </label>
                    <input
                      type="text"
                      // name="first-name"
                      // id="first-name"
                      {...register("first_name", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      })}
                      autoComplete="given-name"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    {errors["first_name"] && (
                      <p className="mt-2 text-sm text-red-600">
                        {errors["first_name"].message}
                      </p>
                    )}
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="last_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Last name
                    </label>
                    <input
                      type="text"
                      // name="last_name"
                      // id="last_name"
                      {...register("last_name", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      })}
                      autoComplete="family-name"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    {errors["last_name"] && (
                      <p className="mt-2 text-sm text-red-600">
                        {errors["last_name"].message}
                      </p>
                    )}
                  </div>

                  <div className="col-span-6 sm:col-span-4">
                    <label
                      htmlFor="email_address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email address
                    </label>
                    <input
                      type="text"
                      // name="email-address"
                      // id="email-address"
                      {...register("email_address", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                        validate: (value) =>
                          validator.isEmail(value) ||
                          "Please enter a valid email address",
                      })}
                      autoComplete="email"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    {errors["email_address"] && (
                      <p className="mt-2 text-sm text-red-600">
                        {errors["email_address"].message}
                      </p>
                    )}
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Country
                    </label>
                    <select
                      // id="country"
                      // name="country"
                      disabled
                      // {...register("country", { required: true })}
                      autoComplete="country-name"
                      className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option>United States</option>
                      {/* <option>Canada</option> */}
                      {/* <option>Mexico</option> */}
                    </select>
                    <p
                      className="mt-2 text-sm text-gray-500"
                      id="email-description"
                    >
                      U.S. Addresses Only
                      <br />
                      <a
                        href="mailto:info@giftcardtrack.com?subject=Requesting%20a%20card%20in%20another%20country&body=Dear%20GiftCardTrack.com%2C%0D%0A%0D%0AI%20would%20like%20to%20create%20a%20card%20using%20the%20following%20postal%20address%3A%0D%0A%0D%0A%3Center%20your%20address%20here%3E"
                        className="text-sm text-blue-500 hover:underline"
                      >
                        Contact us to request another country.
                      </a>
                    </p>
                    {/* <p className="mt-2 text-sm text-gray-500">
                
              </p> */}
                  </div>

                  <div className="col-span-6">
                    <label
                      htmlFor="address_line1"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Address line 1
                    </label>
                    <input
                      type="text"
                      // name="address_line1"
                      // id="address_line1"
                      {...register("address_line1", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                        maxLength: {
                          value: 50,
                          message:
                            "Please reduce address line 1 to max length of 50 or less",
                        },
                      })}
                      autoComplete="address-line1"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    {errors["address_line1"] && (
                      <p className="mt-2 text-sm text-red-600">
                        {errors["address_line1"].message}
                      </p>
                    )}
                  </div>

                  <div className="col-span-6">
                    <label
                      htmlFor="address_line2"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Address line 2
                    </label>
                    <input
                      type="text"
                      // name="address_line2"
                      // id="address_line2"
                      {...register("address_line2", {
                        maxLength: {
                          value: 50,
                          message:
                            "Please reduce address line 2 to max length of 50 or less",
                        },
                      })}
                      autoComplete="address-line2"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    {errors["address_line2"] && (
                      <p className="mt-2 text-sm text-red-600">
                        {errors["address_line2"].message}
                      </p>
                    )}
                  </div>

                  <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium text-gray-700"
                    >
                      City
                    </label>
                    <input
                      type="text"
                      // name="city"
                      // id="city"
                      {...register("city", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      })}
                      autoComplete="address-level2"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    {errors["city"] && (
                      <p className="mt-2 text-sm text-red-600">
                        {errors["city"].message}
                      </p>
                    )}
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label
                      htmlFor="state"
                      className="block text-sm font-medium text-gray-700"
                    >
                      State / Province
                      {/* / Territory */}
                    </label>
                    <select
                      // id="country"
                      // name="country"
                      {...register("state", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                        validate: (value) =>
                          value !== "NONE_SELECTED" || "This field is required",
                      })}
                      autoComplete="address-level1"
                      className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      {/* <option value={null} title={"None"}>
                    None
                  </option> */}
                      <option disabled value="NONE_SELECTED">
                        Select an option...
                      </option>

                      {Object.keys(US_STATES).map((state_code) => (
                        <option
                          key={state_code}
                          value={state_code}
                          title={state_code}
                        >
                          {US_STATES[state_code]}
                        </option>
                      ))}
                    </select>
                    {errors["state"] && (
                      <p className="mt-2 text-sm text-red-600">
                        {errors["state"].message}
                      </p>
                    )}
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label
                      htmlFor="postal_code"
                      className="block text-sm font-medium text-gray-700"
                    >
                      ZIP / Postal code
                    </label>
                    <input
                      type="text"
                      // name="postal-code"
                      // id="postal-code"
                      {...register("postal_code", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                        validate: (value) =>
                          validator.isPostalCode(value, "US") ||
                          "Please enter a valid ZIP / Postal code",
                      })}
                      autoComplete="postal_code"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    {errors["postal_code"] && (
                      <p className="mt-2 text-sm text-red-600">
                        {errors["postal_code"].message}
                      </p>
                    )}
                  </div>
                </>
              )}

              <div className="col-span-6">
                <Controller
                  control={control}
                  name="card_selection"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <RadioCards
                      value={value}
                      onChange={onChange}
                      error={error}
                      offer={offer}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: "You must select a gift card",
                    },
                  }}
                />
              </div>

              <div className="col-span-6">
                <div className="flex items-center">
                  <input
                    // id="remember-me"
                    name="agree_terms"
                    {...register("agree_terms", {
                      required: {
                        value: true,
                        message: "You must agree to the terms and conditions",
                      },
                    })}
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="agree_terms"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    I agree to the terms and conditions listed below
                  </label>
                </div>
                {errors["agree_terms"] && (
                  <p className="mt-2 text-sm text-red-600">
                    {errors["agree_terms"].message}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              type="submit"
              disabled={formSubmitting}
              className="bg-green-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Submit
              {formSubmitting && <Spinner className="ml-2 -mr-0.5 h-4 w-4" />}
            </button>
          </div>
        </div>
      </form>
      <Modal
        visible={resultModal}
        content={
          <>
            <div className="text-lg leading-6 font-medium space-y-1">
              <h3 id="modal-headline">Frequently Asked Questions</h3>
            </div>
            {/* <FAQs /> */}
          </>
        }
        onDismiss={() => setResultModal(false)}
      />
    </>
  );
};

export default PersonalDetails;
