/* This example requires Tailwind CSS v2.0+ */
import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { MailIcon } from "@heroicons/react/outline";
import GiftCardAlert from "./GiftCardAlert";

const cardOptions = [
  {
    id: 1,
    title: "Starbucks",
    logoUrl:
      "https://upload.wikimedia.org/wikipedia/en/d/d3/Starbucks_Corporation_Logo_2011.svg",
    description: "Last message sent an hour ago",
    deliveryTime: "Delivered to your email within 1 business day",
    url: "https://www.starbucks.com/gift",
  },
  {
    id: 2,
    title: "Chipotle",
    logoUrl:
      "https://upload.wikimedia.org/wikipedia/en/3/3b/Chipotle_Mexican_Grill_logo.svg",
    description: "Last message sent an hour ago",
    deliveryTime: "Delivered to your email within 1 business day",
    url: "https://www.chipotle.com/gifts-and-gear",
  },
  {
    id: 3,
    title: "Panera Bread",
    logoUrl:
      "https://upload.wikimedia.org/wikipedia/commons/4/49/Panera_Bread_wordmark.svg",
    description: "Last message sent an hour ago",
    deliveryTime: "Delivered to your email within 1 business day",
    url: "https://www.panerabread.com/en-us/app/egift.html",
  },
  // {
  //   id: 4,
  //   title: "DoorDash",
  //   logoUrl:
  //     "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/DoorDash_Logo.svg/640px-DoorDash_Logo.svg.png",
  //   description: "Last message sent an hour ago",
  //   deliveryTime: "Delivered to your email within 1 business day",
  // },
  {
    id: 4,
    title: "Jersey Mike's Subs",
    logoUrl:
      "https://upload.wikimedia.org/wikipedia/commons/9/91/Jersey_Mike%27s_logo.svg",
    deliveryTime: "Delivered to your email within 1 business day",
  },
  {
    id: 5,
    title: "Qdoba",
    logoUrl:
      "https://upload.wikimedia.org/wikipedia/commons/9/9c/Qdoba_Logo.svg",
    deliveryTime: "Delivered to your email within 1 business day",
  },
  {
    id: 6,
    title: "Subway",
    logoUrl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Subway_2016_logo.svg/640px-Subway_2016_logo.svg.png",
    description: "Last message sent an hour ago",
    deliveryTime: "Delivered to your email within 1 business day",
    url: "https://www.subway.com/en-us/subwaycard",
  },
  {
    id: 7,
    title: "Domino's",
    logoUrl:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3e/Domino%27s_pizza_logo.svg/477px-Domino%27s_pizza_logo.svg.png",
    deliveryTime: "Delivered to your email within 1 business day",
    url: "https://www.dominos.com/en/pages/giftcard/",
  },
  // {
  //   id: 8,
  //   title: "GrubHub",
  //   logoUrl:
  //     "https://upload.wikimedia.org/wikipedia/en/thumb/5/56/GrubHub_2021.svg/640px-GrubHub_2021.svg.png",
  //   description: "Last message sent an hour ago",
  //   deliveryTime: "Delivered to your email within 1 business day",
  // },
  // {
  //   id: 6,
  //   title: "Uber Eats",
  //   logoUrl:
  //     "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Uber_Eats_2020_logo.svg/640px-Uber_Eats_2020_logo.svg.png",
  //   description: "Last message sent an hour ago",
  //   deliveryTime: "Delivered to your email within 1 business day",
  // },
];

const mailingLists = [
  {
    id: 1,
    title: "Newsletter",
    description: "Last message sent an hour ago",
    users: "621 users",
  },
  {
    id: 2,
    title: "Existing Customers",
    description: "Last message sent 2 weeks ago",
    users: "1200 users",
  },
  {
    id: 3,
    title: "Trial Users",
    description: "Last message sent 4 days ago",
    users: "2740 users",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function RadioCards({ onChange, value, error, offer }) {
  const [selectedOption, setSelectedOption] = useState();

  // https://tailwindui.com/components/application-ui/forms/radio-groups#component-45612766894822db447a2837d79355f5
  return (
    <>
      {!offer?.redeemed && (
        <div className="mb-4">
          <GiftCardAlert />
        </div>
      )}
      <RadioGroup value={value} onChange={onChange}>
        <RadioGroup.Label className="text-base font-medium text-gray-900">
          Select your desired gift card
        </RadioGroup.Label>

        <p className="mt-2 text-sm text-gray-500" id="email-description">
          Click a card to select it
        </p>

        <p className="mt-2 text-sm text-red-600">{error?.message}</p>

        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
          {cardOptions.map((option) => (
            <RadioGroup.Option
              key={option.id}
              value={option.title}
              className={({ checked, active }) =>
                classNames(
                  checked ? "border-transparent" : "border-gray-300",
                  active ? "ring-2 ring-indigo-500" : "",
                  "relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
                )
              }
            >
              {({ checked, active }) => (
                <>
                  <div className="flex flex-col w-full">
                    <RadioGroup.Label
                      as="span"
                      className="block text-sm font-medium text-gray-900"
                    >
                      <div className="flex-1 flex">
                        <div className="w-full">{option.title}</div>
                        <CheckCircleIcon
                          className={classNames(
                            !checked ? "invisible" : "",
                            "h-5 w-5 text-indigo-600"
                          )}
                          aria-hidden="true"
                        />
                      </div>
                    </RadioGroup.Label>
                    {/* https://flaviocopes.com/how-to-align-center-vertically-tailwind/ */}
                    <div className="h-16 w-full my-4 px-8 sm:px-0 flex items-center justify-center">
                      <img
                        class="max-h-full mx-auto"
                        src={option.logoUrl}
                        alt={option.title}
                      />
                    </div>
                    <RadioGroup.Description
                      as="span"
                      className="mt-1 flex items-center text-sm text-gray-500"
                    >
                      $10 gift card to {option.title}
                      {/* {mailingList.description} */}
                    </RadioGroup.Description>
                    <RadioGroup.Description
                      as="span"
                      className="mt-1 text-sm font-medium text-gray-900"
                    >
                      {option.deliveryTime}
                    </RadioGroup.Description>
                  </div>
                  <div
                    className={classNames(
                      active ? "border" : "border-2",
                      checked ? "border-indigo-500" : "border-transparent",
                      "absolute -inset-px rounded-lg pointer-events-none"
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
          {/* <RadioGroup.Option
          key={"check"}
          value={"check"}
          className={({ checked, active }) =>
            classNames(
              checked ? "border-transparent" : "border-gray-300",
              active ? "ring-2 ring-indigo-500" : "",
              "relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
            )
          }
        >
          {({ checked, active }) => (
            <>
              <div className="flex flex-col w-full">
                <RadioGroup.Label
                  as="span"
                  className="block text-sm font-medium text-gray-900"
                >
                  <div className="flex-1 flex">
                    <div className="w-full">{"Check by Mail"}</div>
                    <CheckCircleIcon
                      className={classNames(
                        !checked ? "invisible" : "",
                        "h-5 w-5 text-indigo-600"
                      )}
                      aria-hidden="true"
                    />
                  </div>
                </RadioGroup.Label>
                <div className="h-16 w-full my-4 px-8 sm:px-0 flex items-center justify-center">
                  <MailIcon class="max-h-10" />
                </div>
                <RadioGroup.Description
                  as="span"
                  className="mt-1 flex items-center text-sm text-gray-500"
                >
                  $10 check mailed to your provided address
                </RadioGroup.Description>
                <RadioGroup.Description
                  as="span"
                  className="mt-1 text-sm font-medium text-gray-900"
                >
                  {"Delivered in 1-2 weeks"}
                </RadioGroup.Description>
              </div>
              <div
                className={classNames(
                  active ? "border" : "border-2",
                  checked ? "border-indigo-500" : "border-transparent",
                  "absolute -inset-px rounded-lg pointer-events-none"
                )}
                aria-hidden="true"
              />
            </>
          )}
        </RadioGroup.Option> */}
        </div>
      </RadioGroup>
    </>
  );
}
