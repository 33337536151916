import { ExclamationIcon } from "@heroicons/react/solid";

const GiftCardAlert = () => (
  <div className="rounded-md bg-yellow-50 p-4 text-left">
    <div className="flex">
      <div className="flex-shrink-0">
        <ExclamationIcon
          className="h-5 w-5 text-yellow-400"
          aria-hidden="true"
        />
      </div>
      <div className="ml-3">
        <h3 className="text-sm font-medium text-yellow-900">
          Our banking partner is experiencing problems issuing generic payment
          cards.
        </h3>
        <div className="mt-2 text-sm text-yellow-800">
          <p>
            An issue with our banking partner has caused virtual meal cards to
            be declined at some merchants. As a result of this situation, we
            cannot issue generic payment cards.
          </p>
          <p>
            Instead, you can select a virtual gift card for the same amount from
            the list below.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default GiftCardAlert;
